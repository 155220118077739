import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import debug from 'debug';

const log = debug('cs:CanonicalUrlService');
@Injectable({
  providedIn: 'root',
})
export class CanonicalUrlService {
  constructor(private router: Router) {}

  init() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        log('removing canonicals');
        this.removeAll();
      });
  }

  insert(canonicalUrl): void {
    this.removeAll();
    const canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    canonical.setAttribute('href', canonicalUrl);
    log('inserting canonical', canonicalUrl);
    document.head.appendChild(canonical);
  }

  removeAll() {
    document.querySelectorAll('link[rel=canonical]').forEach((i) => i.remove());
  }
}
